import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { compose } from 'recompose'

import SEO from '../components/seo'

import withPageContext from '../pageContext'
import withLayout from '../layout'

const IndexPage = ({ intl }) => (
    <React.Fragment>
        <SEO title="404: Not found" />
        <div className="container">
            <div className="col text-center py-5">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </div>
    </React.Fragment>
)

IndexPage.propTypes = {
    intl: intlShape.isRequired,
}

export default compose(
    withPageContext,
    withLayout,
    injectIntl
)(IndexPage)
